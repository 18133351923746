import * as React from 'react'
import style from './add-edit-team.css'
import {
  applications,
  AutoToggleAppsRecord,
  DependentAppsRecord,
  IApplication,
} from 'constants/user/applications'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useStores } from 'utils/hooks/useStores'
import classNames from 'classnames'
import {
  Dropdown,
  DropdownSelectableItem,
  InputField,
  Checkbox,
  TextToggler,
  Icons,
} from 'plume-ui'
import { AccountStore } from '../account-store'
import { Button } from 'components/button'
import { Loader } from 'components/loader'
import { AddEditOption } from 'interfaces/api/portal/user-api'
import { Team } from 'interfaces/api/portal/company-api'
import { InputMessage } from 'plume-ui/dist/components/InputField/InputField'
import { TeamApplications } from './team-constants'
import { getAppFeaturesValue, getDefaultAppFeatures, isAppFeatureAvailable } from './team.helpers'
import { parseJsonSafe } from 'helpers/general-helpers'
import { RoleOption } from 'modules/dashboard/user/user-store'
import deepEqual from 'fast-deep-equal'
import { getCompanyAllowedApplications } from 'helpers/company-helpers'
import { commonStyle } from 'style/commonly-used-styles'
import { Tooltip } from 'components/tooltip'

type DependenceError = { dependent: string; dependecy: string }

type SetTeamProps = {
  onClose?: () => Promise<void>
  team?: Team
  accountStore: AccountStore
  action: AddEditOption
}

const getAdvancedRole = (
  advancedOptionsTranslation: IApplication['advancedOptionsTranslation'],
  features: string[],
) => {
  if (!features?.length) {
    return null
  }
  const featuresSorted = [...features].sort()
  for (const option of advancedOptionsTranslation) {
    const requiredArray = [...option.requiredOptions].sort()
    const areEqual = deepEqual(featuresSorted, requiredArray)
    if (areEqual) {
      return option.value
    }
  }
}

export const SetTeam = observer((props: SetTeamProps) => {
  const { accountStore, action, team } = props
  const originalTeamName = team?.name || ''
  const appStore = useStores()
  const { t } = useTranslation()
  const { isLoadingModal, teams } = accountStore
  const [isEdited, setIsEdited] = React.useState(false)
  const [teamName, setTeamName] = React.useState(originalTeamName || '')
  const [teamDescription, setTeamDescription] = React.useState(team?.description || '')
  const company = appStore?.authStore?.currentUser?.company
  const [dependenceError, setDependenceError] = React.useState<DependenceError>(null)
  const [autoToggleApplications] = React.useState<AutoToggleAppsRecord>(
    parseJsonSafe(process.env.AUTO_TOGGLE_APPS),
  )
  const [dependentApps] = React.useState<DependentAppsRecord>(
    parseJsonSafe(process.env.DEPENDENT_APPS),
  )

  const portalAppId = process.env.OKTA_CLIENT_ID

  function teamApplicationsInitializer() {
    const teamApplications = applications.reduce((prevApps, a) => {
      const companyTeamApp = team?.apps?.find(da => da.id === a.id)
      const teamApplication: TeamApplications = {
        [a.id]: {
          selected: !!companyTeamApp || null,
          role: companyTeamApp?.role || null,
          features: companyTeamApp?.features || getDefaultAppFeatures(a, company),
          showAdvanced: a.advancedOptionsTranslation
            ? getAdvancedRole(a.advancedOptionsTranslation, companyTeamApp?.features) === undefined
            : false,
        },
      }
      if (a.id === portalAppId) {
        teamApplication[a.id].selected = true
      }
      return { ...prevApps, ...teamApplication }
    }, {} as TeamApplications)
    return teamApplications
  }

  function teamApplicationReducer(
    prevTeamApplications: TeamApplications,
    value: {
      appId?: string
      selected?: boolean
      role?: string
      features?: string[]
      showAdvanced?: boolean
      resetTeamApps?: boolean
    },
  ) {
    if (value.resetTeamApps) {
      return teamApplicationsInitializer()
    }
    const prevApplicationState = prevTeamApplications[value.appId]
    setIsEdited(true)
    return {
      ...prevTeamApplications,
      [value.appId]: {
        selected: value.selected === undefined ? prevApplicationState?.selected : value.selected,
        role: value.role === undefined ? prevApplicationState?.role : value.role,
        features: value.features === undefined ? prevApplicationState?.features : value.features,
        showAdvanced:
          value.showAdvanced === undefined
            ? prevApplicationState?.showAdvanced
            : value.showAdvanced,
      },
    }
  }

  const [teamApplications, dispatchTeamApplications] = React.useReducer(
    teamApplicationReducer,
    null,
    teamApplicationsInitializer,
  )

  React.useEffect(() => {
    dispatchTeamApplications({ resetTeamApps: true })
  }, [team])

  const teamDuplicateNameError = teams.find(
    d =>
      d?.name?.toLowerCase() === teamName?.toLowerCase() &&
      d?.name?.toLowerCase() !== originalTeamName?.toLowerCase(),
  )
  const teamInputFieldErrorMessages: Partial<InputMessage>[] = []
  const teamDescriptionInputFieldErrorMessages: Partial<InputMessage>[] = []
  if (!teamName) {
    teamInputFieldErrorMessages.push({ status: 'error', message: t('errors.requiredField') })
  }
  if (teamDuplicateNameError) {
    teamInputFieldErrorMessages.push({
      status: 'error',
      message: t('teams.duplicateTeamNamesNotAllowed'),
    })
  }
  const isNameValid = /^[a-zA-Z0-9]([A-Za-z0-9\-_ ]*[a-zA-Z0-9])?$/.test(teamName)
  if (!!teamName && !isNameValid) {
    teamInputFieldErrorMessages.push({
      status: 'error',
      message: t('teams.teamNameInvalid'),
    })
  }
  const isTeamNameTooLong = teamName?.length > 50
  if (isTeamNameTooLong) {
    teamInputFieldErrorMessages.push({
      status: 'error',
      message: t('errors.max50CharsAllowed'),
    })
  }

  let isRoleUnselected = false
  for (const appId in teamApplications) {
    const appInfo = applications.find(a => a.id === appId)
    if (
      (!!appInfo.roleOptions &&
        teamApplications[appId].selected &&
        !teamApplications[appId].role) ||
      (!!appInfo.featuresOptions &&
        teamApplications[appId].selected &&
        !teamApplications[appId]?.features?.length) ||
      (appInfo.advancedOptionsTranslation &&
        teamApplications[appId].selected &&
        !teamApplications[appId]?.showAdvanced &&
        !getAdvancedRole(appInfo.advancedOptionsTranslation, teamApplications[appId]?.features))
    ) {
      isRoleUnselected = true
      break
    }
  }

  const isTeamDescriptionTooLong = teamDescription?.length > 1000
  if (isTeamDescriptionTooLong) {
    teamDescriptionInputFieldErrorMessages.push({
      status: 'error',
      message: t('errors.maxCustomCharsAllowed', { charNumber: 1000 }),
    })
  }

  const companyAllowedApplications = getCompanyAllowedApplications(company)

  const isSubmitDisabled =
    !isEdited ||
    !!teamInputFieldErrorMessages.length ||
    isRoleUnselected ||
    isTeamNameTooLong ||
    isTeamDescriptionTooLong ||
    !!dependenceError

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    if (isSubmitDisabled) {
      return
    }

    if (action === 'add') {
      props.accountStore.validateAndSubmitAddTeam(teamApplications, teamName, teamDescription)
    } else if (action === 'edit') {
      props.accountStore.validateAndSubmitEditTeam(
        teamApplications,
        teamName,
        originalTeamName,
        team.groupId,
        teamDescription,
      )
    }
  }

  const getRoleText = (role: string, roleOptions: readonly RoleOption[]) => {
    return t(roleOptions?.find(i => i.value === role)?.text)
  }

  let _depError: DependenceError = null
  for (const app of applications) {
    if (teamApplications[app.id]?.selected && dependentApps?.[app.id]) {
      for (const dependence of dependentApps?.[app.id]) {
        const doesDependentSatisfy =
          !dependence.dependentRole || teamApplications[app.id]?.role === dependence.dependentRole
        const isDependencyIncorrect = dependence?.allowedRoles
          ? !dependence?.allowedRoles?.includes(
              teamApplications[dependence.dependencyAppId]?.role,
            ) || !teamApplications[dependence.dependencyAppId]?.selected
          : !teamApplications[dependence.dependencyAppId]?.selected

        if (doesDependentSatisfy && isDependencyIncorrect) {
          const dependentRole = getRoleText(dependence.dependentRole, app.roleOptions) || ''
          const dependencyApplication = applications.find(a => a.id === dependence.dependencyAppId)
          const dependencyRoles = dependence.allowedRoles
            ? dependence?.allowedRoles
                ?.map(r => getRoleText(r, dependencyApplication.roleOptions))
                ?.join(` ${t('common.or')} `)
            : ''

          _depError = {
            dependecy: `${dependencyApplication?.name} ${dependencyRoles}`,
            dependent: `${app.name} ${dependentRole}`,
          }
        }
      }
    }
  }
  if (!deepEqual(dependenceError, _depError)) {
    setDependenceError(_depError)
  }

  return (
    <div className={style.editApplicationTeamRoot}>
      {isEdited && <div className={style.labelText}>{t('teams.pleaseSaveChanges')}</div>}

      <div className={style.inputFieldWrapper}>
        <InputField
          classes={current => ({
            ...current,
            root: classNames(current.root, style.inputFieldModifications),
          })}
          type="text"
          label={t('teams.teamName')}
          value={teamName}
          required={true}
          noClearIcon={team?.isLocked}
          messages={[
            ...teamInputFieldErrorMessages,
            {
              status: 'hint',
              message: t('teams.teamNameContains'),
            },
          ]}
          disabled={team?.isLocked}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
            e.stopPropagation()
            setTeamName(e.target.value)
            setIsEdited(true)
          }}
        />
      </div>
      <div className={style.inputFieldWrapper}>
        <InputField
          classes={current => ({
            ...current,
            root: classNames(current.root, style.inputFieldModifications),
          })}
          type="text"
          label={t('teams.teamDescription')}
          value={teamDescription}
          required={false}
          noClearIcon={false}
          disabled={false}
          messages={teamDescriptionInputFieldErrorMessages}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
            e.stopPropagation()
            setTeamDescription(e.target.value)
            setIsEdited(true)
          }}
        />
      </div>

      {dependenceError && (
        <div className={style.error}>
          {t('applications.dependentAppError', {
            dependent: dependenceError.dependent,
            dependency: dependenceError.dependecy,
          })}
        </div>
      )}
      <div className={style.gridWrapper}>
        <>
          <div className={style.popoverToolsAppNameLabel}>{t('applications.appName')}</div>
          <div className={style.popoverToolsLicensesLabel}>{t('teams.role')}</div>
          <div></div>
        </>
        {applications.map((app, index) => {
          const isApplicationAllowed = !!companyAllowedApplications.find(caa => caa.id === app.id)
          if (!isApplicationAllowed) {
            return
          }

          const featuresDisplayText = teamApplications[app.id]?.features
            ?.map(f => {
              const optionText = app.featuresOptions.find(fo => fo.value === f)?.text
              return optionText ? t(optionText) : ''
            })
            ?.filter(o => o)
            ?.join(', ')

          const stopPropagationPreventDefault = (
            e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
          ) => {
            e.preventDefault()
            e.stopPropagation()
          }

          const dropdownDisabled = !teamApplications[app.id]?.selected

          const hasAdvanced = !!app.advancedOptionsTranslation

          const role = !hasAdvanced
            ? teamApplications[app.id]?.role
            : getAdvancedRole(app.advancedOptionsTranslation, teamApplications[app.id]?.features)

          const roleDisplayText = !hasAdvanced
            ? app?.roleOptions?.find(ro => ro.value === teamApplications[app.id]?.role)?.text
            : app?.advancedOptionsTranslation?.find(ao => ao.value === role)?.text

          const roleOptions = !hasAdvanced ? app?.roleOptions : app?.advancedOptionsTranslation

          return (
            <React.Fragment key={index}>
              {/* <div style={{ backgroundImage: `url(${bg})` }} className={style.toolIcon} /> */}
              <div
                className={classNames(
                  style.popoverToolText,
                  !teamApplications[app.id]?.selected && style.popoverToolTextNotSelected,
                )}
              >
                <Tooltip
                  placement="topLeft"
                  overlay={t(app.description)}
                  align={{
                    offset: [0, -5],
                  }}
                  trigger={['hover']}
                  mouseLeaveDelay={0}
                >
                  <div className={commonStyle.marginLeft20px}>{app.name}</div>
                </Tooltip>

                {hasAdvanced && (
                  <>
                    <Checkbox
                      label={t('teams.showAdvanced')}
                      name={'teams.showAdvanced'}
                      disabled={!teamApplications[app.id]?.selected}
                      classes={curr => ({
                        ...curr,
                        root: classNames(
                          curr.root,
                          style.checkboxModifications,
                          !teamApplications[app.id]?.selected && commonStyle.cursorDefault,
                        ),
                      })}
                      checked={!!teamApplications[app.id]?.showAdvanced}
                      onChange={() =>
                        dispatchTeamApplications({
                          appId: app.id,
                          showAdvanced: !teamApplications[app.id]?.showAdvanced,
                        })
                      }
                    />
                  </>
                )}
              </div>
              <div className={style.roleWrapper}>
                {(hasAdvanced ? !teamApplications[app.id]?.showAdvanced : !!app?.roleOptions) ? (
                  <Dropdown
                    closeOnItemClick={true}
                    classes={defaultClasses => ({
                      ...defaultClasses,
                      root: classNames(defaultClasses.root, style.teamRootFont),
                      button: classNames(defaultClasses.button, style.dropdownItem),
                      label: classNames(
                        defaultClasses.label,
                        style.dropdownLabel,
                        !role && teamApplications[app.id]?.selected && style.dropdownLabelWarning,
                      ),
                      icon: classNames(defaultClasses.icon, style.dropdownCaretIcon),
                    })}
                    disabled={dropdownDisabled}
                    label={role ? t(roleDisplayText) : t('teams.pleaseSelect')}
                  >
                    {roleOptions?.map(item => (
                      <DropdownSelectableItem
                        key={item.value}
                        selected={role === item.value}
                        onClick={e => {
                          if (dropdownDisabled) {
                            stopPropagationPreventDefault(e)
                          } else {
                            dispatchTeamApplications({
                              appId: app.id,
                              role: !hasAdvanced ? item.value : undefined,
                              features: hasAdvanced
                                ? [
                                    ...(app?.advancedOptionsTranslation?.find(
                                      o => o.value === item.value,
                                    )?.requiredOptions || []),
                                  ]
                                : undefined,
                            })
                          }
                        }}
                      >
                        {t(item.text)}
                      </DropdownSelectableItem>
                    ))}
                  </Dropdown>
                ) : (
                    hasAdvanced ? teamApplications[app.id]?.showAdvanced : !!app?.featuresOptions
                  ) ? (
                  <Dropdown
                    closeOnItemClick={false}
                    classes={defaultClasses => ({
                      ...defaultClasses,
                      root: classNames(defaultClasses.root, style.teamRootFont),
                      button: classNames(defaultClasses.button, style.dropdownItem),
                      label: classNames(
                        defaultClasses.label,
                        style.featureDropdownLabel,
                        !teamApplications[app.id]?.features?.length &&
                          teamApplications[app.id]?.selected &&
                          style.dropdownLabelWarning,
                      ),
                      icon: classNames(defaultClasses.icon, style.dropdownCaretIcon),
                    })}
                    disabled={dropdownDisabled}
                    label={
                      teamApplications[app.id]?.features?.length
                        ? featuresDisplayText
                        : t('teams.pleaseSelect')
                    }
                  >
                    {app?.featuresOptions?.map(item => {
                      if (!isAppFeatureAvailable(app, item.value, company)) {
                        return
                      }

                      return (
                        <DropdownSelectableItem
                          key={item.value}
                          selected={teamApplications[app.id]?.features?.includes(item.value)}
                          onClick={e => {
                            if (dropdownDisabled) {
                              stopPropagationPreventDefault(e)
                            } else {
                              dispatchTeamApplications({
                                appId: app.id,
                                features: getAppFeaturesValue(
                                  app,
                                  teamApplications[app.id]?.features,
                                  item.value,
                                ),
                              })
                            }
                          }}
                        >
                          {t(item.text)}
                        </DropdownSelectableItem>
                      )
                    })}
                  </Dropdown>
                ) : (
                  <div className={style.emptyCellBackground} />
                )}
                {!!app?.advancedInfo && (
                  <Tooltip
                    placement="top"
                    overlay={t('applications.learnAboutApplicationRoles')}
                    align={{
                      offset: [0, -5],
                    }}
                    trigger={['hover']}
                    mouseLeaveDelay={0}
                  >
                    <a href={app.advancedInfo} target="_blank" className={style.infoWrapper}>
                      <Icons.InfoIcon className={style.infoIconSize} />
                    </a>
                  </Tooltip>
                )}
              </div>
              <Tooltip
                placement="top"
                overlay={t('teams.mustHavePortalAccess')}
                align={{
                  offset: [0, -5],
                }}
                trigger={app.id === portalAppId ? ['hover'] : []}
                mouseLeaveDelay={0}
              >
                <TextToggler
                  classes={curr => ({
                    ...curr,
                    root: classNames(curr.root, style.togglerBackground),
                    toggleContainer: classNames(curr.toggleContainer, style.togglerAdjustment),
                  })}
                  title={''}
                  selected={!!teamApplications[app.id]?.selected}
                  onToggle={() => {
                    if (app.id === portalAppId) {
                      return
                    }
                    dispatchTeamApplications({
                      appId: app.id,
                      selected: !teamApplications[app.id]?.selected,
                      role: teamApplications[app.id]?.role || app.defaultRole,
                    })

                    const autoSelectionToggles = autoToggleApplications?.[app.id]
                    if (autoSelectionToggles) {
                      for (const toggle of autoSelectionToggles) {
                        if (toggle.toggledSelection === !teamApplications[app.id]?.selected) {
                          for (const targetDispatch of toggle.targetDispatches) {
                            dispatchTeamApplications(targetDispatch)
                          }
                        }
                      }
                    }
                  }}
                />
              </Tooltip>
              {app.id === portalAppId &&
                Array(3)
                  .fill(0)
                  .map((_, ind) => <div key={ind} style={{ height: 5 }}></div>)}
            </React.Fragment>
          )
        })}
      </div>

      <div className={style.btnDiv}>
        {isLoadingModal && <Loader />}
        <Button
          disabled={isSubmitDisabled}
          className={style.sendInviteBtn}
          onClick={e => handleSubmit(e)}
        >
          {t('btn.save')}
        </Button>
      </div>
    </div>
  )
})
