import { FieldState, FormState } from 'formstate'
import {
  email,
  max40chars,
  max50chars,
  max80chars,
  required,
  noAngleBrackets,
} from 'utils/form/validators'
import { observable, computed, action, runInAction, when, toJS } from 'mobx'
import {
  IUser,
  IExtendedUser,
  SetUserPayloadResponse,
  AddEditOption,
} from 'interfaces/api/portal/user-api'
import { AppStore } from 'stores/app-store'
import { ExportStore, HeaderColumn } from 'modules/export/export-store'
import { errorsMap } from 'constants/errors'
import { showAlert } from 'utils/show-alert'
import { getUserLanguage } from 'utils/locale/portal-locale'
import { showCloneUserProgressModal } from './user'
import { parseJsonSafe, stringifyError } from 'helpers/general-helpers'
import i18next from 'i18next'
import {
  AppProgressWrapper,
  ICompany,
  UserExportTeam,
  UserExportTeamsWrapper,
} from 'interfaces/api/portal/company-api'
import {
  addEditUsersSuccessEvent,
  userExportTeamAppsEvent,
  userErrorEvent,
  userExportTeamAppsErrorEvent,
} from 'constants/websocket-event-identifiers'
import { mixpanelActions } from 'utils/mixpanelActions'
import { notify, toast } from 'plume-ui'
import moment from 'moment'
import { applications } from 'constants/user/applications'
import { canAccess } from 'modules/auth/auth-action-permission'

const AVATAR_COLORS = ['#3c396f', '#25bede', '#20bb8c', '#da0061', '#e2b400', '#6504af']

export interface RoleOption {
  readonly value: string
  readonly text: string
}

export class DashboardUsersStore {
  exportStore: ExportStore

  public tempPassword = new FieldState('')

  public cloneLogin = new FieldState('')

  public clonePassword = new FieldState('')

  public firstName = new FieldState('').validators(
    required('First Name'),
    max40chars,
    noAngleBrackets,
  )
  public lastName = new FieldState('').validators(required('Last Name'), max50chars)
  public email = new FieldState('').validators(required('Email Address'), email, max80chars)
  public oldEmail = ''
  public jobTitle = new FieldState('').validators(max50chars)
  public team = new FieldState<string>('')

  public lastLogin = ''
  public lastFailedLogin = ''

  public isReleaseNotesContact = new FieldState<boolean>(false).validators()
  public isServiceDisruptionContact = new FieldState<boolean>(false).validators()
  public isSecurityContact = new FieldState<boolean>(false).validators()

  public editedUserStatus: IUser['status']
  public editedUserPortalRole: string
  public editedUserHasMultipleCompanies = false
  public editedUserIsCloned = false

  public editUserId = ''

  @observable users: IUser[] = []

  @observable public cloneQueue: any = []

  @observable
  clonedUserId = ''

  @observable
  isLoading = false

  @observable
  usersError = false

  @observable
  isLoadingModal = false

  @observable
  isUserItemLoading = false

  @observable
  public serverError = ''

  @observable
  public isUserInfoModalVisible = false

  @observable
  currentTab = 0

  @observable searchedUsers: IUser[] = []

  public searchInput = new FieldState('').validators()

  @observable
  isSearchActive = false

  @observable
  isSearchLoading = false

  @observable
  toolNeedsToBeSelected = false

  public supportReason = new FieldState<string>('').validators(required('Reason'))

  public supportDescription = new FieldState('').validators(required('This field'))

  @observable
  public supportRequestError = ''

  @observable
  public temporaryPasswordGenerated = false

  @observable
  public temporaryPasswordGenerateError = false

  @observable
  public cloneUserGenerated = false

  @observable
  public cloneUserGeneratedError = false

  @observable
  public accessiblePortals: ICompany[] = []

  public setSupportContactEmail: (email: string) => void = null

  @action
  public clearSupportData = async () => {
    this.supportReason.reset()
    this.supportDescription.reset()
    this.supportRequestError = ''
  }

  @computed
  public get currentUserId() {
    return this.appStore.authStore.currentUser.id
  }

  @computed
  public get areAddUserRequiredFieldsEmpty() {
    return this.email.value === '' || this.firstName.value === '' || this.lastName.value === ''
  }

  constructor(private readonly appStore: AppStore) {
    when(
      () => !!this.appStore.authStore.currentUser.company,
      () => {
        this.exportStore = new ExportStore()
      },
    )
  }

  // Users preview

  @action
  fetchUsers = async () => {
    this.isLoading = true

    try {
      this.setUsersError(false)
      const areTeamsLoaded = !!this.appStore.accountStore.teams
      const {
        users: extendedUsers,
        teams,
        sfDataLoadingFailed,
      } = await this.appStore.userApi.listUsers(
        this.appStore.authStore.currentUser.company.partnerId,
        !areTeamsLoaded,
      )

      if (sfDataLoadingFailed) {
        mixpanelActions.track('List Users - Loading sf data failed', {
          'Partner Id': this.appStore.authStore.currentUser?.company?.partnerId,
        })
        showAlert({
          title: i18next.t('errors.warning'),
          message: i18next.t('errors.loadingAuxiliaryUserDataFailed'),
          isCloseShowing: false,
        })
      }

      const users: IUser[] = extendedUsers.map(user => {
        const randomInt = Math.floor(Math.random() * (AVATAR_COLORS.length - 1)) + 1
        const color = AVATAR_COLORS[randomInt]

        return {
          id: user?.id,
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
          jobTitle: user?.jobTitle,
          phoneNumber: user?.phoneNumber,
          locale: user?.locale,
          team: user?.team,
          status: user?.status,
          lastLogin: user?.lastLogin,
          cloned: user?.cloned,
          canClone: user?.canClone,
          releaseNotesContact: user?.releaseNotesContact,
          serviceDisruptionContact: user?.serviceDisruptionContact,
          securityContact: user?.securityContact,
          editable: false,
          avatarColor: color,
          idp_role: user?.idp_role,
        }
      })
      users.map(user => {
        user.editable = this.setEditable(user)
      })
      runInAction(() => {
        this.users = users
        if (!areTeamsLoaded) {
          this.appStore.accountStore.setTeams(teams)
        }
        this.isLoading = false
      })
    } catch (e) {
      if (e) {
        this.setIsLoading(false)
        this.setUsersError(true)
      }
    }
  }

  @action
  fetchClonedUsersQueue = async () => {
    try {
      const response: any = await this.appStore.userApi.clonedUsersQueue()
      const user = response && response[this.clonedUserId]

      // clone done
      if (user && user.current === user.totalGroups) {
        this.appStore.actionModalStore.removeModal()
        this.clonedUserId = ''
      } else {
        this.setClonedQueue(response)
      }
    } catch (e) {}
  }

  @action
  updateUserLocaleIfNeeded = async () => {
    const userId = this.currentUserId
    const user = this.users.find(user => user.id === userId)

    if (user) {
      const userLanguage = getUserLanguage()

      if (userLanguage !== user.locale) {
        await this.updateUserLocale(userLanguage)
        user.locale = userLanguage
      }
    }
  }

  @action.bound
  setClonedQueue(data: any) {
    this.cloneQueue = data
  }

  @action.bound
  resetUsers() {
    this.users = []
  }

  @action.bound
  setCurrentTab(tab: number) {
    this.currentTab = tab
  }

  @observable
  public notificationUserColumnsShown = false

  @action.bound
  setNotificationUserColumnsShown(value: boolean) {
    this.notificationUserColumnsShown = value
  }

  @computed
  public get invitedUsers(): IUser[] {
    return this.users.filter(
      user =>
        !user.cloned &&
        (user.status === 'PROVISIONED' || user.status === 'RECOVERY') &&
        !user.lastLogin,
    )
  }

  @computed
  public get activeUsers(): IUser[] {
    return this.users.filter(
      user =>
        !user.cloned &&
        (user.status === 'ACTIVE' || user.status === 'RECOVERY' || user.status === 'PROVISIONED') &&
        user.lastLogin,
    )
  }

  @computed
  public get suspendedUsers(): IUser[] {
    return this.users.filter(user => !user.cloned && user.status === 'SUSPENDED')
  }

  @computed
  public get deactivatedUsers(): IUser[] {
    return this.users.filter(user => !user.cloned && user.status === 'DEPROVISIONED')
  }

  @computed
  public get lockedUsers(): IUser[] {
    return this.users.filter(user => !user.cloned && user.status === 'LOCKED_OUT')
  }

  @computed
  public get expiredPasswordUsers(): IUser[] {
    return this.users.filter(user => !user.cloned && user.status === 'PASSWORD_EXPIRED')
  }

  @computed
  public get clonedUsers(): IUser[] {
    return this.users.filter(user => user.cloned)
  }

  @computed
  public get toolUsers(): IUser[] {
    return this.users.filter(user => !user.cloned && user.status === 'SUSPENDED')
  }

  // Users search actions

  @action
  setIsSearchActive = (value: boolean) => {
    this.isSearchActive = value
  }

  @action
  setIsSearchLoading = (value: boolean) => {
    this.isSearchLoading = value
  }

  @action
  handleSearch = (value: string) => {
    this.setIsSearchActive(true)
    this.setIsSearchLoading(true)
    this.searchedUsers = this.users.filter(user => {
      const { firstName, lastName } = user
      const fullName = `${firstName.toLowerCase()} ${lastName.toLowerCase()}`
      return (
        (fullName.includes(value.toLowerCase()) ||
          user.email.toLowerCase().includes(value.toLowerCase())) &&
        !user.cloned
      )
    })
    this.setIsSearchLoading(false)
  }

  @action
  handleEndSearch = () => {
    this.setIsSearchLoading(false)
    this.setIsSearchActive(false)
    this.searchedUsers = []
    this.searchInput.value = ''
  }

  @action
  refreshSearchResults() {
    this.isSearchActive && this.handleSearch(this.searchInput.value)
  }

  @computed
  public get invitedSearchedUsers(): IUser[] {
    return this.searchedUsers.filter(
      user => (user.status === 'PROVISIONED' || user.status === 'RECOVERY') && !user.lastLogin,
    )
  }

  @computed
  public get activeSearchedUsers(): IUser[] {
    return this.searchedUsers.filter(
      user =>
        (user.status === 'ACTIVE' || user.status === 'RECOVERY' || user.status === 'PROVISIONED') &&
        user.lastLogin,
    )
  }

  @computed
  public get suspendedSearchedUsers(): IUser[] {
    return this.searchedUsers.filter(user => user.status === 'SUSPENDED')
  }

  @computed
  public get deactivatedSearchedUsers(): IUser[] {
    return this.searchedUsers.filter(user => user.status === 'DEPROVISIONED')
  }

  @computed
  public get lockedOutSearchUsers(): IUser[] {
    return this.searchedUsers.filter(user => user.status === 'LOCKED_OUT')
  }

  @computed
  public get expiredPasswordSearchUsers(): IUser[] {
    return this.searchedUsers.filter(user => user.status === 'PASSWORD_EXPIRED')
  }

  @computed
  public get formHasError() {
    return this.formState.hasError
  }

  // Add/Edit users handling

  private formState = new FormState({
    firstName: this.firstName,
    lastName: this.lastName,
    email: this.email,
    jobTitle: this.jobTitle,
  }).validators($ => {
    if (!$.email.value) {
      return 'Invalid email.'
    }
  })

  @action
  removeAllSetUserListeners() {
    this.appStore.appWebSocket.removeAllListeners(addEditUsersSuccessEvent)
    this.appStore.appWebSocket.removeAllListeners(userErrorEvent)
  }

  @action
  public clearFormState() {
    this.firstName.reset()
    this.lastName.reset()
    this.email.reset()
    this.jobTitle.reset()
    this.oldEmail = ''
    this.editUserId = ''
    this.team.reset()
    this.editedUserHasMultipleCompanies = false
    this.editedUserIsCloned = false
    this.isServiceDisruptionContact.reset()
    this.isReleaseNotesContact.reset()
    this.isSecurityContact.reset()
    this.removeAllSetUserListeners()
    this.clearServerError()
  }

  @action
  public clearResetPasswordForm() {
    this.firstName.reset()
    this.lastName.reset()
    this.tempPassword.reset()
    this.editUserId = ''
    this.setIsGeneratedTemporaryPassword(false)
    this.setGenerateTemporaryPasswordError(false)
  }

  @action
  public clearCloneUserForm() {
    this.firstName.reset()
    this.lastName.reset()
    this.cloneLogin.reset()
    this.clonePassword.reset()
    this.setIsGeneratedCloneUser(false)
    this.setIsGeneratedCloneUserError(false)
  }

  public setEditable(user?: IUser): boolean {
    if (!!user.idp_role) {
      return false
    }
    if (canAccess('editUsers', this.appStore.authStore.currentUser)) {
      return true
    }
    return false
  }

  @action
  public fillEditForm(editedUser: IUser): void {
    this.firstName.value = editedUser.firstName
    this.lastName.value = editedUser.lastName
    this.email.value = editedUser.email
    this.oldEmail = editedUser.email
    this.editUserId = editedUser.id
    this.jobTitle.value = editedUser.jobTitle
    this.team.value =
      this.appStore.accountStore?.teams?.find(t => t.name === editedUser.team)?.name || ''
    this.editedUserStatus = editedUser.status
    this.editedUserIsCloned = editedUser.cloned
    this.isReleaseNotesContact.value = editedUser.releaseNotesContact
    this.isServiceDisruptionContact.value = editedUser.serviceDisruptionContact
    this.isSecurityContact.value = editedUser.securityContact
  }

  @action
  public async fillUserInfoFields(user: IUser) {
    this.setIsUserItemLoading(true)
    this.email.value = user.email
    this.lastLogin = user.lastLogin
    const failedLogin = await this.appStore.userApi.findUserLastFailedLogin(user.id)
    runInAction(() => {
      this.lastFailedLogin = !failedLogin ? i18next.t('common.noFailedLoginData') : failedLogin
    })
    this.setIsUserItemLoading(false)
  }

  @action
  public async clearUserInfoFields() {
    this.email.reset()
    this.lastLogin = ''
    this.lastFailedLogin = ''
  }

  @action
  public fillResetPasswordForm(editedUser: IUser): void {
    this.firstName.value = editedUser.firstName
    this.lastName.value = editedUser.lastName
    this.editUserId = editedUser.id
  }

  @action
  public fillCloneUserForm(editedUser: IUser): void {
    this.firstName.value = editedUser.firstName
    this.lastName.value = editedUser.lastName
    this.editUserId = editedUser.id
  }

  @action
  public fillDeleteUserForm(editedUserId: string): void {
    this.editUserId = editedUserId
  }

  @action
  public fillChannelPartnerUser(editedUser: IUser) {
    this.firstName.value = editedUser.firstName
    this.lastName.value = editedUser.lastName
    this.jobTitle.value = editedUser.jobTitle
    this.team.value = editedUser.team
  }

  @action setUserPhone(email: string, phone: string) {
    const user = this.users.find(u => u.email === email)
    if (user) user.phoneNumber = phone
  }

  @computed
  public get error() {
    return this.formState.error || this.serverError
  }

  @computed
  public get currentUserCanDelete(): boolean {
    return canAccess('editUsers', this.appStore.authStore.currentUser)
  }

  @computed
  public get editedUserCanBeDeleted(): boolean {
    return (
      this.editUserId !== this.currentUserId
      // may want to double check
      // && (editedUserRole === PortalRole.admin || editedUserRole === PortalRole.standard)
    )
  }

  @action
  public async validateAndSubmit(userAction: AddEditOption) {
    await this.formState.validate()

    if (!this.formState.hasError) {
      this.clearServerError()
      await this.submit(userAction)
    }
  }

  @action
  initAddEditUserWebSocketEvents() {
    this.removeAllSetUserListeners()

    this.appStore.appWebSocket.on(addEditUsersSuccessEvent, (message: string) => {
      const user: SetUserPayloadResponse = parseJsonSafe(message)
      user.editable = this.setEditable(user)
      const action = user?.action

      const payload: IUser = {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        jobTitle: user.jobTitle,
        status: user.status,
        editable: user.editable,
        lastLogin: user.lastLogin,
        cloned: user.cloned,
        releaseNotesContact: user.releaseNotesContact,
        serviceDisruptionContact: user.serviceDisruptionContact,
        securityContact: user.securityContact,
        team: user.team,
      }
      this.handleSuccess(payload, action)
    })

    this.appStore.appWebSocket.on(userErrorEvent, (errMessage: string) => {
      this.setSubmitUserProgress(0)
      const errorObject: { prefillValues?: IExtendedUser; popupMessageKey?: string } =
        parseJsonSafe(errMessage)
      if (errorObject?.prefillValues) {
        showAlert({
          title: 'users.addExistingUser',
          message: i18next.t('users.addUserToChannelPartnerDescription', {
            firstName: errorObject.prefillValues.firstName,
            lastName: errorObject.prefillValues.lastName,
            email: this.email.value,
          }),
          buttonText: 'common.confirm',
          onAcknowledge: async () => {
            this.fillChannelPartnerUser({
              firstName: errorObject.prefillValues.firstName,
              lastName: errorObject.prefillValues.lastName,
              jobTitle: errorObject.prefillValues.jobTitle,
              team: errorObject.prefillValues.team,
            })
            this.submit('add', true)
          },
          onClose: () => undefined,
        })
      } else if (errorObject?.popupMessageKey) {
        const errorTitleRecord: Record<string, string> = {
          userSamlMasteredError: 'samlUserErrorTitle',
        }
        showAlert({
          title: i18next.t(`errors.${errorTitleRecord[errorObject?.popupMessageKey]}`),
          message: i18next.t(`errors.${errorObject?.popupMessageKey}`),
          onAcknowledge: () => this.removeModal(),
        })
      }
      this.handleServerError(!errorObject ? errMessage : '')
    })
  }

  @observable
  submitUserProgress = 0

  @action.bound
  setSubmitUserProgress(submitUserProgress: number) {
    this.submitUserProgress = submitUserProgress
  }

  @action
  fillProgressBar(finishIn = 60) {
    setTimeout(() => {
      if (this.submitUserProgress < 0.9 - 1 / finishIn && this.isLoadingModal) {
        this.setSubmitUserProgress(this.submitUserProgress + 1 / finishIn)
        this.fillProgressBar(finishIn)
      }
    }, 1000)
  }

  @action
  private async submit(userAction: AddEditOption, confirmAddToParent = false) {
    const { isEmployee } = this.appStore.authStore.currentUser

    this.isLoadingModal = true
    this.fillProgressBar()
    const existingData = this.users.find(u => u.id === this.editUserId)
    const selectedCompanyId = !isEmployee && window.localStorage.getItem('selectedCompany')
    const partnerId = this.appStore.authStore.currentUser.company.partnerId

    const userData: SetUserPayloadResponse = {
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      email: this.email.value,
      jobTitle: this.jobTitle.value,
      partnerId: (selectedCompanyId ? selectedCompanyId : partnerId).toString(),
      releaseNotesContact: this.isReleaseNotesContact.value,
      serviceDisruptionContact: this.isServiceDisruptionContact.value,
      securityContact: this.isSecurityContact.value,
      team: this.team.value,
      confirmAddToParent,
      clientId: this.appStore.appWebSocket?.id,
    }

    if (existingData?.cloned) {
      userData.cloned = true
    }

    try {
      if (userAction === 'add') {
        await this.appStore.userApi.createUser(userData)
      } else {
        await this.appStore.userApi.updateUser(
          {
            ...userData,
            oldEmail: this.oldEmail,
          },
          this.editUserId,
        )
      }
    } catch (e) {
      this.handleServerError(e)
    }
  }

  @action
  public clearServerError() {
    this.serverError = ''
  }

  @action.bound
  private updateTeamsUsersCount(oldTeamName: string, newTeamName: string) {
    const teamsVar = toJS(this.appStore?.accountStore?.teams)
    const oldTeamIndex = teamsVar?.findIndex(t => t.name === oldTeamName)
    const newTeamIndex = teamsVar?.findIndex(t => t.name === newTeamName)

    if (oldTeamIndex !== -1) {
      teamsVar[oldTeamIndex].usersCount -= 1
    }

    if (newTeamIndex !== -1) {
      teamsVar[newTeamIndex].usersCount += 1
    }
    this.appStore?.accountStore?.setTeams(teamsVar)
  }

  @action.bound
  private async handleSuccess(user: IUser, userAction: AddEditOption) {
    if (userAction === 'add') {
      this.updateTeamsUsersCount('', user.team)
      this.users.push(user)
    } else {
      this.updateTeamsUsersCount(this.users.find(u => u.id === user.id).team, user.team)
      this.users.find((o, i) => {
        if (o.id === this.editUserId) {
          this.users[i] = user
          return true
        }
      })
    }
    this.setSupportContactEmail && this.setSupportContactEmail(this.email.value)
    this.refreshSearchResults()
    const { isEmployee } = this.appStore.authStore.currentUser
    if (!isEmployee) {
      await this.refreshCurrentUserApps()
    }
    this.removeModal()
    if (userAction === 'add') {
      await showAlert({
        title: 'users.inviteSuccess',
        message: i18next.t('users.inviteSuccessMessage', {
          email: user.email,
        }),
        buttonText: 'users.inviteOk',
        isCloseShowing: true,
      })
    }
  }

  @action
  public async refreshCurrentUserApps() {
    const results = await Promise.all([
      this.appStore.companyApi.getCompanies(),
      this.appStore.userApi.getUserAssignedApplications(),
    ])

    await this.appStore.authStore.setCurrentPartnerUser(
      this.appStore.authStore.currentUser,
      results[0],
      results[1],
      this.appStore.authStore.currentUser.company.partnerId,
    )
  }

  @action
  public async updateUserLocale(locale: string) {
    try {
      await this.appStore.userApi.updateUserLocale(locale)
    } catch (e) {
      console.log(e)
    }
  }

  @action.bound
  private removeModal() {
    this.appStore.actionModalStore.removeModal()
    this.clearFormState()
    this.setSupportContactEmail = null
    this.accessiblePortals = []
    this.setSubmitUserProgress(0)
    this.setIsLoadingModal(false)
  }

  @action
  private setIsLoading = (value: boolean) => {
    this.isLoading = value
  }

  @action
  private setUsersError = (value: boolean) => {
    this.usersError = value
  }

  @action
  private setIsLoadingModal = (value: boolean) => {
    this.isLoadingModal = value
  }

  @action
  private handleServerError(error: string) {
    let errorMessage = error
    switch (error) {
      case 'login: An object with this field already exists in the current organization':
        errorMessage = 'User with this email address already exists in the current organization.'
        break
      default:
        break
    }
    this.serverError = errorsMap[errorMessage] || errorMessage
    this.isLoadingModal = false
    this.isLoading = false
  }

  @action
  handleErrorInPopup(e: string) {
    const errorMessage = typeof e !== 'string' ? stringifyError(e) : e
    notify({
      title: i18next.t('errors.error'),
      body: errorMessage,
      type: 'error',
    })
    this.setIsLoadingModal(false)
  }

  @action
  public toggleUserInfoModalVisibility = async () => {
    this.isUserInfoModalVisible = !this.isUserInfoModalVisible
  }

  // User actions

  @action
  deleteUser = async (fromUsersTab?: boolean) => {
    this.isLoadingModal = true
    if (fromUsersTab) {
      this.setIsUserItemLoading(true)
    }
    try {
      await this.appStore.userApi.deleteUser(this.editUserId)
      this.onDeleteSuccess(this.editUserId, fromUsersTab)
    } catch (e) {
      if (fromUsersTab) {
        showAlert({
          message: `${e}`,
          title: i18next.t('errors.error'),
          onAcknowledge: async () => {
            this.setIsUserItemLoading(false)
            window.location.reload()
          },
        })
      } else {
        this.handleServerError(e)
      }
    }
  }

  @action
  forceDeleteUser = async (email: string) => {
    this.isLoadingModal = true
    try {
      await this.appStore.userApi.forceDeleteUser(email)
      this.onForceDeleteSuccess(email)
    } catch (e) {
      this.handleServerError(e)
    }
  }

  @action
  onDeleteSuccess(id: string, fromUsersTab?: boolean) {
    const userToBeDeleted = this.users.find(user => user.id === id)

    if (userToBeDeleted) {
      this.updateTeamsUsersCount(this.users.find(u => u.id === id).team, '')
      this.users = this.users.filter(u => u.id !== userToBeDeleted.id)
      this.refreshSearchResults()
    }
    if (fromUsersTab) {
      this.clearFormState()
      this.setIsUserItemLoading(false)
      this.setIsLoadingModal(false)
    } else {
      this.removeModal()
    }
  }

  @action
  onForceDeleteSuccess(email: string) {
    const userToBeDeleted = this.users.find(user => user.email === email)

    if (userToBeDeleted) {
      this.users = this.users.filter(u => u.id !== userToBeDeleted.id)
      this.refreshSearchResults()
    }

    this.removeModal()
  }

  @action
  suspendUser = async () => {
    this.isLoadingModal = true

    await this.appStore.userApi.suspendUser(this.editUserId)
    await this.fetchUsers()
    this.refreshSearchResults()
    this.removeModal()
  }

  @action
  unsuspendUser = async () => {
    this.isLoadingModal = true

    await this.appStore.userApi.unsuspendUser(this.editUserId)
    await this.fetchUsers()
    this.refreshSearchResults()
    this.removeModal()
  }

  @action
  activateUser = async () => {
    this.isLoadingModal = true

    try {
      await this.appStore.userApi.activateUser(this.editUserId)
      await this.fetchUsers()
      this.refreshSearchResults()
      this.removeModal()
    } catch (e) {
      this.handleErrorInPopup(e)
    }
  }

  @action
  generateTemporaryPassword = async (userId: string) => {
    this.setIsLoadingModal(true)
    this.setIsGeneratedTemporaryPassword(false)
    this.setGenerateTemporaryPasswordError(false)

    try {
      const result: any = await this.appStore.userApi.expirePassword(userId)
      await this.fetchUsers()
      this.setIsLoadingModal(false)
      this.setIsGeneratedTemporaryPassword(true)
      this.setGenerateTemporaryPasswordError(false)
      this.tempPassword.onChange(result.tempPassword)
      return result
    } catch (e) {
      this.setIsLoadingModal(false)
      this.setIsGeneratedTemporaryPassword(false)
      this.setGenerateTemporaryPasswordError(true)
    }
  }

  @action
  cloneUser = async (userId: string) => {
    this.setIsLoadingModal(true)
    this.setIsGeneratedCloneUser(false)
    this.setIsGeneratedCloneUserError(false)

    try {
      const result: any = await this.appStore.userApi.cloneUser(userId)
      await this.fetchUsers()
      this.setIsLoadingModal(false)
      this.setIsGeneratedCloneUser(true)
      this.setIsGeneratedCloneUserError(false)
      this.cloneLogin.onChange(result.login)
      this.clonePassword.onChange(result.password)

      this.clonedUserId = result.clonedOktaUserId

      this.showCloneProgressModal()

      this.appStore.userApi.cloneUserGroups(result.oktaUserId, result.clonedOktaUserId)

      return result
    } catch (e) {
      this.setIsLoadingModal(false)
      this.setIsGeneratedCloneUser(false)
      this.setIsGeneratedCloneUserError(errorsMap[e] || e)
    }
  }

  @action
  showCloneProgressModal = async () => {
    showCloneUserProgressModal(this.appStore.actionModalStore)
  }

  reactivateUser = async (userId: string) => {
    this.setIsUserItemLoading(true)
    await this.appStore.userApi.reactivateUser(userId)
    this.setIsUserItemLoading(false)
  }

  unlockUser = async (userId: string) => {
    this.setIsUserItemLoading(true)
    await this.appStore.userApi.unlockUser(userId)
    await this.fetchUsers()
    this.refreshSearchResults()
    this.setIsUserItemLoading(false)
  }

  @action
  public setIsUserItemLoading = async (value: boolean) => {
    this.isUserItemLoading = value
  }

  @action
  public setIsGeneratedTemporaryPassword = async (value: boolean) => {
    this.temporaryPasswordGenerated = value
  }

  @action
  public setGenerateTemporaryPasswordError = async (error: boolean) => {
    this.temporaryPasswordGenerateError = error
  }

  @action
  public setIsGeneratedCloneUser = async (value: boolean) => {
    this.cloneUserGenerated = value
  }

  @action
  public setIsGeneratedCloneUserError = async (error: boolean) => {
    this.cloneUserGeneratedError = error
  }

  @action
  public async initShowPortals(email: string) {
    this.setIsLoadingModal(true)
    try {
      const apResponse = await this.appStore.userApi.getAccessiblePortalCompanies(email)
      this.setAccessiblePortals(apResponse)
      this.setIsLoadingModal(false)
    } catch (e) {
      this.handleServerError(e)
    }
  }

  @action.bound
  setAccessiblePortals(accessiblePortals: ICompany[]) {
    this.accessiblePortals = accessiblePortals
  }

  // Export

  public exportUsersCSV(headers: HeaderColumn[], users: any, fileName: string) {
    this.exportStore.exportCSVFile(headers, users, fileName)
  }

  @observable
  usersExportProgress = 0

  @action.bound
  setUsersExportProgress(usersExportProgress: number) {
    this.usersExportProgress = usersExportProgress
  }

  @observable
  usersExportToastId: string = null

  @action.bound
  setUsersExportToastId(toastId: string) {
    this.usersExportToastId = toastId
  }

  @action
  removeTeamExportListeners() {
    this.appStore.appWebSocket.removeAllListeners(userExportTeamAppsEvent)
    this.appStore.appWebSocket.removeAllListeners(userExportTeamAppsErrorEvent)
  }

  @action
  initUserExport() {
    this.setUsersExportProgress(1)
    this.removeTeamExportListeners()

    this.appStore.appWebSocket.on(userExportTeamAppsEvent, (message: string) => {
      const teamAppWrapper: AppProgressWrapper = parseJsonSafe(message)
      this.handleUserExportTeamAppsEvent(teamAppWrapper)
    })

    this.appStore.appWebSocket.on(userExportTeamAppsErrorEvent, (errorMessage: string) => {
      this.cleanupUserExport()
      showAlert({
        title: 'errors.error',
        message: errorMessage,
      })
    })

    this.appStore.companyApi.getUserExportTeamApps(
      this.appStore.authStore.currentUser.company.partnerId,
      this.appStore.appWebSocket?.id,
    )
  }

  async createExport(teamsVar: UserExportTeam[]) {
    const { users } = this

    const additionalAppNameHeaders: HeaderColumn[] = []

    applications.forEach(appInfo => {
      for (const team of teamsVar) {
        for (const app of team.apps) {
          if (appInfo.id === app.id) {
            additionalAppNameHeaders.push({ key: app.id, display: appInfo.name })
            return
          }
        }
      }
    })

    const csvUsers = users
      .filter(user => !user.cloned)
      .map(user => {
        return {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          jobTitle: user.jobTitle,
          status: user.status === 'PROVISIONED' ? 'INVITED' : user.status,
          // will ned portal permissions exported
          // portalRole: getFormattedPortalRole(user.portalRole),
          team: user.team ? user.team : '',
          lastLogin: user.lastLogin || i18next.t('common.never'),
        } as Record<string, string>
      })
      .sort((u1, u2) => (u1.status > u2.status ? 1 : -1))

    for (const user of csvUsers) {
      const team = teamsVar.find(t => t.name === user.team)
      if (!team) {
        continue
      }

      for (const app of team.apps) {
        const appId = app.id
        const appInfo = applications.find(a => a.id === appId)
        if (app) {
          const i18nAppRole = appInfo?.roleOptions?.find(r2 => r2.value === app.role)?.text

          const getI18nFeature = (feature: string) =>
            appInfo?.featuresOptions?.find(r2 => r2.value === feature)?.text

          user[appId] = i18nAppRole
            ? i18next.t(i18nAppRole)
            : app.features
            ? app.features?.map(f => i18next.t(getI18nFeature(f))).join(' + ')
            : i18next.t('common.assigned')
        }
      }
    }

    const fileName = `${moment().utc().format('YYYYMMDHHmmss')}-plume_portal_users.csv`
    const headers = [
      { key: 'name', display: i18next.t('users.name').toUpperCase() },
      { key: 'email', display: i18next.t('common.email').toUpperCase() },
      { key: 'jobTitle', display: i18next.t('common.jobTitle').toUpperCase() },
      { key: 'status', display: i18next.t('common.status').toUpperCase() },
      // { key: 'portalRole', display: i18next.t('users.portalRole').toUpperCase() },
      { key: 'team', display: i18next.t('users.team').toUpperCase() },
      { key: 'lastLogin', display: i18next.t('common.lastLogin').toUpperCase() },
      ...additionalAppNameHeaders,
    ]

    this.appStore.usersStore.exportUsersCSV(headers, csvUsers, fileName)
    this.cleanupUserExport()
  }

  cleanupUserExport() {
    if (this.usersExportToastId) {
      toast.remove(this.usersExportToastId)
      this.setUsersExportToastId(null)
    }
    this.setUsersExportProgress(0)
    this.removeTeamExportListeners()
  }

  handleUserExportTeamAppsEvent(userExportTeamsWrapper: UserExportTeamsWrapper) {
    const progressArray = userExportTeamsWrapper.progress.split('/')
    const progress = {
      count: Number.parseInt(progressArray[0]),
      total: Number.parseInt(progressArray[1]),
    }

    this.setUsersExportProgress(Math.round((progress.count / progress.total) * 100) || 0)

    if (progress.count === progress.total) {
      this.createExport(userExportTeamsWrapper.teamsAppsExport)
    }
  }

  // Helpers

  public getUserRole(role: string) {
    switch (role) {
      case 'none':
        return 'None'
      case 'groupAdmin':
        return 'Admin'
      case 'groupSupport':
        return 'Support'
      case 'groupSupportTechnician':
        return 'Technician'
      default:
        return 'None'
    }
  }
}
