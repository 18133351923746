import { PortalBaseApi } from './portal-base-api'
import {
  IUser,
  IUserUpdate,
  IUserApi,
  SetPasswordPayload,
  IAssignedApplication,
  IListUsersResponse,
  SetUserPasswordResponse,
  CloneUserResponse,
} from 'interfaces/api/portal/user-api'
import { ICompany } from 'interfaces/api/portal/company-api'
import { sortUserApps } from '../../helpers/user-helpers'
import { applications } from '../../constants/user/applications'

export class UserApi extends PortalBaseApi implements IUserApi {
  getBaseUrl() {
    return `${super.getBaseUrl()}/users`
  }

  setPassword(token: string, setPasswordPayload: SetPasswordPayload) {
    return this.post<SetUserPasswordResponse>(`/set-password?token=${token}`, setPasswordPayload)
  }

  getToken(activationToken: string) {
    return this.get<string>(`/token?activationToken=${activationToken}`)
  }

  public async createUser(userPayload: IUser) {
    return await this.post<void>(`/create`, userPayload)
  }

  public async updateUser(userPayload: IUserUpdate, userId: string) {
    return await this.post<void>(`/update/${userId}`, userPayload)
  }

  public async listUsers(partnerId: string, loadTeamData?: boolean) {
    return await this.get<IListUsersResponse>(`/list-users/${partnerId}`, {
      params: { loadTeamData },
    })
  }

  public async deleteUser(userId: string) {
    return await this.delete(`/delete/${userId}`)
  }

  public async forceDeleteUser(email: string) {
    return await this.post(`/delete-force`, { email })
  }

  public async suspendUser(userId: string) {
    return await this.post(`/suspend/${userId}`)
  }

  public async unsuspendUser(userId: string) {
    return await this.post<void>(`/unsuspend/${userId}`)
  }

  public async activateUser(userId: string) {
    return await this.post<void>(`/activate/${userId}`)
  }

  public async expirePassword(userId: string) {
    return await this.post(`/expire-password/${userId}`)
  }

  public async cloneUser(userId: string) {
    return await this.post<CloneUserResponse>(`/clone/${userId}`, {}, { timeout: 5000000 })
  }

  public async cloneUserGroups(userId: string, clonedUserId: string) {
    return await this.post(`/clone-groups/${userId}/user/${clonedUserId}`)
  }

  public async clonedUsersQueue() {
    return await this.get('/clone-queue')
  }

  public async updateUserLocale(locale: string) {
    return await this.post<void>(`/set-locale/${locale}`)
  }

  public async checkIsUserActive(email: string) {
    return await this.get<boolean>(`/is-active/${email}`)
  }

  public async getUserCreationOrigin(email: string) {
    return await this.get<string>(`/created-by/${email}`)
  }

  public async getUserAssignedApplications(): Promise<IAssignedApplication[]> {
    const userAppsUnsorted = await this.get<IAssignedApplication[]>(`/userassigned-apps`)
    return sortUserApps(
      userAppsUnsorted,
      applications.map(a => a.id),
    )
  }

  public async reactivateUser(userId: string) {
    return await this.post<void>(`/reactivate/${userId}`)
  }

  public async unlockUser(userId: string) {
    return await this.post<void>(`/unlock/${userId}`)
  }

  public async expireRecoveryPassword(userEmail: string) {
    return await this.post<void>(`/expire-recovery-password`, {
      email: userEmail,
    })
  }
  public async getAccessiblePortalCompanies(email: string): Promise<ICompany[]> {
    return await this.get(`/accessible-portal-companies/${email}`)
  }

  public async findUserLastFailedLogin(userId: string): Promise<string> {
    return await this.get(`/last-failed-login/${userId}`)
  }

  // public async getExternalOktaUsers(
  //   email: string,
  //   partnerId: string,
  // ): Promise<ExternalFrontlineAccessResponse> {
  //   return await this.get(`/external-okta-users/${encodeURIComponent(email)}/company/${partnerId}`)
  // }

  // public async addExternalUserToCloud(
  //   email: string,
  //   cloud: SalesforceCloud,
  //   selected: boolean,
  //   partnerId: string,
  // ): Promise<void> {
  //   return await this.post(`/external-okta-users/add`, {
  //     email,
  //     cloud,
  //     selected,
  //     partnerId,
  //   })
  // }

  public async getOrSetupIdpUserCompany(clientId: string) {
    return await this.get<void>(`/idp-user-companies`, { params: { clientId } })
  }

  public async acceptTermsAndConditions() {
    return await this.post<void>(`/accept-terms-conditions`)
  }
}
