import {
  harvestClarityValue,
  harvestGenerateValue,
  harvestNurtureValue,
} from 'constants/applications/harvest.constants'
import { SalesforceCloud } from 'constants/salesforce.constants'
import { DeepReadonly } from 'helpers/general-helpers'
import { RoleOption } from 'modules/dashboard/user/user-store'

export const PortalOptionsAdvanced = [
  {
    value: 'userManagement',
    text: 'applications.portalUserManagement',
  },
  {
    value: 'applicationManagement',
    text: 'applications.portalApplicationManagement',
  },
  {
    value: 'portalManagement',
    text: 'applications.portalPortalManagement',
  },
  {
    value: 'plumeCloudManagement',
    text: 'applications.portalPlumeCloudManagement',
  },
  {
    value: 'apiManagement',
    text: 'applications.portalApiManagement',
  },
  {
    value: 'networkManagement',
    text: 'applications.portalNetworkManagement',
  },
  {
    value: 'basicAccess',
    text: 'applications.portalBasicAccess',
  },
] as const

export const PortalAdvancedOptionsTranslation = [
  {
    value: 'standard',
    text: 'users.portalRoleSuperStandard',
    requiredOptions: ['basicAccess'],
  },
  {
    value: 'admin',
    text: 'users.portalRoleAdmin',
    requiredOptions: [
      'basicAccess',
      'userManagement',
      'applicationManagement',
      'portalManagement',
      'plumeCloudManagement',
    ],
  },
  {
    value: 'superAdmin',
    text: 'users.portalRoleSuperadmin',
    requiredOptions: [
      'basicAccess',
      'userManagement',
      'applicationManagement',
      'portalManagement',
      'plumeCloudManagement',
      'apiManagement',
      'networkManagement',
    ],
  },
] as const

const NocOptions = [
  {
    value: 'groupAdmin',
    text: 'users.appRoleAdmin',
  },
  {
    value: 'groupSupport',
    text: 'users.appRoleSupport',
  },
] as const

const CentralOptions = [
  {
    value: 'groupAdmin',
    text: 'users.appRoleAdmin',
  },
  {
    value: 'groupSupport',
    text: 'users.appRoleSupport',
  },
  {
    value: 'groupSupportTechnician',
    text: 'users.appRoleTechnician',
  },
] as const

const SignalOptions = [
  {
    value: 'partnerAdmin',
    text: 'applications.signalRolePartnerAdmin',
  },
  {
    value: 'partnerSupportManagerExternal',
    text: 'applications.signalRolePartnerSupportManagerExternal',
  },
  {
    value: 'partnerSupportManagerInternal',
    text: 'applications.signalRolePartnerSupportManagerInternal',
  },
  {
    value: 'partnerSupportEngineer',
    text: 'applications.signalRolePartnerSupportEngineer',
  },
]

export const HarvestOptions = [
  {
    value: harvestGenerateValue,
    text: 'applications.harvestGenerateRole',
  },
  {
    value: harvestNurtureValue,
    text: 'applications.harvestNurtureRole',
  },
  {
    value: harvestClarityValue,
    text: 'applications.harvestClarityRole',
  },
] as const

export const UpriseOptions = [
  {
    value: 'partnerAdmin',
    text: 'applications.uprisePartnerAdmin',
  },
  {
    value: 'partnerTechnician',
    text: 'applications.uprisePartnerTechnician',
  },
  {
    value: 'partnerSupport',
    text: 'applications.uprisePartnerSupport',
  },
] as const

export const PanoramaOptions = [
  {
    value: 'partnerAdmin',
    text: 'applications.panoramaPartnerAdmin',
  },
  {
    value: 'partnerSupport',
    text: 'applications.panoramaPartnerSupport',
  },
  {
    value: 'partnerSales',
    text: 'applications.panoramaPartnerSales',
  },
] as const

type ApplicationConformation = {
  id: string
  name: string
  description: string
  url: string
  icon: string
  sfProductName?: string
  sfToolName?: string
  environment: string[]
  banner?: string
  extra?: string
  cloud?: SalesforceCloud
  roleOptions?: RoleOption[]
  defaultRole?: string
  featuresOptions?: RoleOption[]
  advancedOptionsTranslation?: {
    value: string
    text: string
    requiredOptions: string[]
  }[]
  advancedInfo?: string
}

const applicationsConstType = [
  {
    id: process.env.OKTA_CLIENT_ID,
    name: 'Portal access',
    description: 'applications.portalDesc',
    url: undefined as string,
    icon: 'public-resources.svg',
    sfProductName: '',
    sfToolName: '',
    environment: ['production', 'staging', 'development'] as string[],
    featuresOptions: PortalOptionsAdvanced as readonly RoleOption[],
    advancedOptionsTranslation: PortalAdvancedOptionsTranslation,
    advancedInfo: `${process.env.PORTAL_API_BASE_URL}/misc/salesforce-digital-experience-sso/app-roles`,
  },
  {
    id: '0oaja8eoq2vFt5nA10h7',
    name: 'Frontline Tier 1 (CI cloud)',
    description: 'applications.centralDesc',
    url: 'https://ci.central-dev.plume.com/',
    icon: 'network-central.svg',
    sfProductName: 'Frontline',
    sfToolName: 'Plume Central',
    environment: ['development'] as string[],
    cloud: 'CI',
    roleOptions: CentralOptions as readonly RoleOption[],
  },
  {
    id: '0oavlcw1ujirVSm5K356',
    name: 'Frontline Tier 1 (Gamma cloud)',
    description: 'applications.centralDesc',
    url: 'https://gamma.central.plume.com',
    icon: 'network-central.svg',
    sfProductName: 'Frontline',
    sfToolName: 'Plume Central',
    environment: ['production', 'staging'] as string[],
    cloud: 'Gamma',
    roleOptions: CentralOptions as readonly RoleOption[],
  },
  {
    id: '0oaok2ovllN6YTDDa356',
    name: 'Frontline Tier 1 (Kappa cloud)',
    description: 'applications.centralDesc',
    url: 'https://kappa.central.plume.com',
    icon: 'network-central.svg',
    sfProductName: 'Frontline',
    sfToolName: 'Plume Central',
    environment: ['production', 'staging'] as string[],
    cloud: 'Kappa',
    roleOptions: CentralOptions as readonly RoleOption[],
  },
  {
    id: '0oavliiygEcuep6ZE356',
    name: 'Frontline Tier 1 (Beta Cloud)',
    description: 'applications.centralDesc',
    extra: 'applications.downloadBetaApps',
    url: 'https://beta.central.plume.com',
    icon: 'network-central.svg',
    environment: ['production', 'staging', 'development'] as string[],
    sfProductName: 'Frontline',
    sfToolName: 'Plume Central Beta',
    cloud: 'Beta' as SalesforceCloud,
    roleOptions: CentralOptions as readonly RoleOption[],
  },
  {
    id: '0oa7xgnbx5tvV0i3L357',
    name: 'Frontline Tier 1 (Iota cloud)',
    description: 'applications.centralDesc',
    url: 'https://iota.central.plume.com',
    icon: 'network-central.svg',
    sfProductName: 'Frontline',
    sfToolName: 'Plume Central',
    environment: ['production', 'staging'] as string[],
    cloud: 'Iota',
    roleOptions: CentralOptions as readonly RoleOption[],
  },
  {
    id: '0oavs57h7H6o8rLIs356',
    name: 'Frontline Tier 1 (OpenSync Cloud)',
    description: 'applications.centralDesc',
    url: 'https://opensync.central.plume.com',
    icon: 'network-central.svg',
    environment: ['production', 'staging', 'development'] as string[],
    sfProductName: 'Frontline',
    sfToolName: 'Plume Central Opensync',
    cloud: 'OpenSync' as SalesforceCloud,
    roleOptions: CentralOptions as readonly RoleOption[],
  },
  {
    id: '0oavlr5jgo4343Eym356',
    name: 'Frontline Tier 1 (Dogfood Cloud)',
    description: 'applications.centralDesc',
    url: 'https://dogfood.central.plume.com',
    icon: 'network-central.svg',
    environment: ['production', 'staging', 'development'] as string[],
    sfProductName: 'Frontline',
    sfToolName: 'Plume Central Dogfood',
    cloud: 'Dogfood' as SalesforceCloud,
    roleOptions: CentralOptions as readonly RoleOption[],
  },
  {
    id: '0oavs5nnvTd6afdsD356',
    name: 'Frontline Tier 1 (Osync Cloud)',
    description: 'applications.centralDesc',
    url: 'https://osync.central.plume.com',
    icon: 'network-central.svg',
    environment: ['production', 'staging', 'development'] as string[],
    sfProductName: 'Frontline',
    sfToolName: 'Plume Central Osync',
    cloud: 'Osync' as SalesforceCloud,
    roleOptions: CentralOptions as readonly RoleOption[],
  },
  {
    id: '0oajlnu7zq0fjP0i00h7',
    name: 'Frontline Tier 2 & 3 (CI cloud)',
    description: 'applications.nocDesc',
    url: 'https://ci.noc-dev.plume.com/',
    icon: 'network-noc.svg',
    sfProductName: 'Frontline',
    sfToolName: 'Plume NOC',
    environment: ['development'] as string[],
    cloud: 'CI' as SalesforceCloud,
    roleOptions: NocOptions as readonly RoleOption[],
  },
  {
    id: '0oa16a2cw1IIfsm7N357',
    name: 'Frontline Tier 2 & 3 (Gamma cloud)',
    description: 'applications.nocDesc',
    url: 'https://gamma.noc.plume.com/',
    icon: 'network-noc.svg',
    sfProductName: 'Frontline',
    sfToolName: 'Plume NOC',
    environment: ['production', 'staging'] as string[],
    cloud: 'Gamma',
    roleOptions: NocOptions as readonly RoleOption[],
  },
  {
    id: '0oa16a23wujewFtSQ357',
    name: 'Frontline Tier 2 & 3 (Kappa cloud)',
    description: 'applications.nocDesc',
    url: 'https://kappa.noc.plume.com/',
    icon: 'network-noc.svg',
    sfProductName: 'Frontline',
    sfToolName: 'Plume NOC',
    environment: ['production', 'staging'] as string[],
    cloud: 'Kappa',
    roleOptions: NocOptions as readonly RoleOption[],
  },
  {
    id: '0oa169rzos6ojMkSk357',
    name: 'Frontline Tier 2 & 3 (Beta Cloud)',
    description: 'applications.nocDesc',
    extra: 'applications.downloadBetaApps',
    url: 'https://beta.noc.plume.com/',
    icon: 'network-noc.svg',
    environment: ['production', 'staging', 'development'] as string[],
    sfProductName: 'Frontline',
    sfToolName: 'Plume NOC Beta',
    cloud: 'Beta' as SalesforceCloud,
    roleOptions: NocOptions as readonly RoleOption[],
  },
  {
    id: '0oa7xgko96Y6ZyX34357',
    name: 'Frontline Tier 2 & 3 (Iota cloud)',
    description: 'applications.nocDesc',
    url: 'https://iota.noc.plume.com/',
    icon: 'network-noc.svg',
    sfProductName: 'Frontline',
    sfToolName: 'Plume NOC',
    environment: ['production', 'staging'] as string[],
    cloud: 'Iota',
    roleOptions: NocOptions as readonly RoleOption[],
  },
  {
    id: '0oa1b6l3tkwVQ1fye357',
    name: 'Frontline Tier 2 & 3 (OpenSync Cloud)',
    description: 'applications.nocDesc',
    url: 'https://opensync.noc.plume.com/',
    icon: 'network-noc.svg',
    environment: ['production', 'staging', 'development'] as string[],
    sfProductName: 'Frontline',
    sfToolName: 'Plume NOC Opensync',
    cloud: 'OpenSync' as SalesforceCloud,
    roleOptions: NocOptions as readonly RoleOption[],
  },
  {
    id: '0oacjghkdGWW0iZMs356',
    name: 'Frontline Tier 2 & 3 (Dogfood Cloud)',
    description: 'applications.nocDesc',
    url: 'https://dogfood.noc.plume.com',
    icon: 'network-noc.svg',
    environment: ['production', 'staging', 'development'] as string[],
    sfProductName: 'Frontline',
    sfToolName: 'Plume NOC Dogfood',
    cloud: 'Dogfood' as SalesforceCloud,
    roleOptions: NocOptions as readonly RoleOption[],
  },
  {
    id: '0oa1c07kumEP40DQ9357',
    name: 'Frontline Tier 2 & 3 (Osync Cloud)',
    description: 'applications.nocDesc',
    url: 'https://osync.noc.plume.com',
    icon: 'network-noc.svg',
    environment: ['production', 'staging', 'development'] as string[],
    sfProductName: 'Frontline',
    sfToolName: 'Plume NOC Osync',
    cloud: 'Osync' as SalesforceCloud,
    roleOptions: NocOptions as readonly RoleOption[],
  },
  {
    id: '0oa12oet8abmbyIde0h8',
    name: 'Signal (CI cloud)',
    description: 'applications.signalDesc',
    url: 'https://ci.signal-stg.plume.com/',
    icon: 'signal.svg',
    sfProductName: 'Signal',
    sfToolName: 'Plume Signal',
    environment: ['development'] as string[],
    cloud: 'CI',
    roleOptions: SignalOptions as readonly RoleOption[],
    defaultRole: 'partnerSupportEngineer',
  },
  {
    id: '0oadbiswq5ZGHwdRI357',
    name: 'Signal (Kappa cloud)',
    description: 'applications.signalDesc',
    url: 'https://kappa.signal.plume.com/',
    icon: 'signal.svg',
    sfProductName: 'Signal',
    sfToolName: 'Plume Signal',
    environment: ['production', 'staging'] as string[],
    cloud: 'Kappa',
    roleOptions: SignalOptions as readonly RoleOption[],
    defaultRole: 'partnerSupportEngineer',
  },
  {
    id: '0oadbiqmaw4nafGoX357',
    name: 'Signal (Gamma cloud)',
    description: 'applications.signalDesc',
    url: 'https://gamma.signal.plume.com',
    icon: 'signal.svg',
    sfProductName: 'Signal',
    sfToolName: 'Plume Signal',
    environment: ['production', 'staging'] as string[],
    cloud: 'Gamma',
    roleOptions: SignalOptions as readonly RoleOption[],
    defaultRole: 'partnerSupportEngineer',
  },
  {
    id: '0oadbini70Iov4IsU357',
    name: 'Signal (Beta Cloud)',
    description: 'applications.signalDesc',
    url: 'https://beta.signal.plume.com',
    icon: 'signal.svg',
    sfProductName: 'Signal',
    sfToolName: 'Plume Signal Beta',
    environment: ['production', 'staging'] as string[],
    cloud: 'Beta' as SalesforceCloud,
    roleOptions: SignalOptions as readonly RoleOption[],
    defaultRole: 'partnerSupportEngineer',
  },
  {
    id: '0oadnilc7yYAy4pya357',
    name: 'Signal Demo',
    description: 'applications.signalDesc',
    url: 'https://demo.signal.plume.com',
    icon: 'signal.svg',
    sfProductName: 'Signal Demo',
    sfToolName: 'Signal Demo',
    environment: ['production', 'staging'] as string[],
    banner: 'common.demo',
  },
  {
    id: '0oa21ivoo3ur88LCa0h8',
    name: 'Panorama',
    description: 'applications.tableauDesc',
    url: 'https://dev.panorama-dev.plume.com/',
    icon: 'network-haystack.svg',
    sfProductName: 'Panorama',
    sfToolName: 'Tableau',
    banner: 'common.new',
    environment: ['development', 'local'] as string[],
    roleOptions: PanoramaOptions as readonly RoleOption[],
  },
  {
    id: '0oa15gqd4rlAZMaFU358',
    name: 'Panorama',
    description: 'applications.tableauDesc',
    url: 'https://prod.panorama.plume.com',
    icon: 'network-haystack.svg',
    sfProductName: 'Panorama',
    sfToolName: 'Tableau',
    banner: 'common.new',
    environment: ['production', 'staging'] as string[],
    roleOptions: PanoramaOptions as readonly RoleOption[],
  },
  {
    id: '0oa15gowr3x9H7qHn0h8',
    name: 'Uprise (CI cloud)',
    description: 'applications.upriseDesc',
    url: 'https://external-dev.sso.plume.com/home/oidc_client/0oa15gowr3x9H7qHn0h8/aln5z7uhkbM6y7bMy0g7',
    icon: 'uprise.svg',
    sfProductName: 'Uprise',
    sfToolName: 'Uprise',
    environment: ['development'] as string[],
    cloud: 'CI',
    roleOptions: UpriseOptions as readonly RoleOption[],
  },
  {
    id: '0oagtwz2bbR6m3rLO357',
    name: 'Uprise (Gamma cloud)',
    description: 'applications.upriseDesc',
    url: 'https://external.sso.plume.com/home/oidc_client/0oagtwz2bbR6m3rLO357/aln177a159h7Zf52X0g8',
    icon: 'uprise.svg',
    sfProductName: 'Uprise',
    sfToolName: 'Uprise',
    environment: ['production', 'staging'] as string[],
    cloud: 'Gamma',
    roleOptions: UpriseOptions as readonly RoleOption[],
  },
  {
    id: '0oagtx7w69VvLm3Ml357',
    name: 'Uprise (Kappa cloud)',
    description: 'applications.upriseDesc',
    url: 'https://external.sso.plume.com/home/oidc_client/0oagtx7w69VvLm3Ml357/aln177a159h7Zf52X0g8',
    icon: 'uprise.svg',
    sfProductName: 'Uprise',
    sfToolName: 'Uprise',
    environment: ['production', 'staging'] as string[],
    cloud: 'Kappa',
    roleOptions: UpriseOptions as readonly RoleOption[],
  },
  {
    id: '0oa15glqqc4MrpK8R0h8',
    name: 'Flex (CI cloud)',
    description: 'applications.flexDesc',
    url: 'https://ci.flex-dev.plume.com',
    icon: 'network-flex.svg',
    sfProductName: 'Flex',
    sfToolName: 'Flex',
    environment: ['development'] as string[],
    cloud: 'CI' as SalesforceCloud,
  },
  {
    id: '0oagvqwcvuXn9BOAo357',
    name: 'Flex (Kappa cloud)',
    description: 'applications.flexDesc',
    url: 'https://kappa.flex.plume.com',
    icon: 'network-flex.svg',
    sfProductName: 'Flex',
    sfToolName: 'Flex',
    environment: ['production', 'staging'] as string[],
    cloud: 'Kappa',
  },
  {
    id: '0oagvqy1mbxOsPH6A357',
    name: 'Flex (Gamma cloud)',
    description: 'applications.flexDesc',
    url: 'https://gamma.flex.plume.com',
    icon: 'network-flex.svg',
    sfProductName: 'Flex',
    sfToolName: 'Flex',
    environment: ['production', 'staging'] as string[],
    cloud: 'Gamma',
  },
  {
    id: '0oagvquy4fOybe5u9357',
    name: 'Flex (Beta Cloud)',
    description: 'applications.flexDesc',
    url: 'https://beta.flex.plume.com',
    icon: 'network-flex.svg',
    environment: ['production', 'staging'] as string[],
    sfProductName: 'Flex',
    sfToolName: 'Flex Beta',
    cloud: 'Beta' as SalesforceCloud,
  },
  {
    id: '0oa15glvqezXd8EHR0h8',
    name: 'Engage (CI cloud)',
    description: 'applications.harvestDesc',
    url: 'https://external-dev.sso.plume.com/home/oidc_client/0oa15glvqezXd8EHR0h8/aln5z7uhkbM6y7bMy0g7',
    icon: 'harvest.svg',
    sfProductName: 'Harvest',
    sfToolName: 'Harvest',
    environment: ['development'] as string[],
    cloud: 'CI',
    featuresOptions: HarvestOptions as readonly RoleOption[],
  },
  {
    id: '0oagvr3jpqhV2GjKt357',
    name: 'Engage (Gamma cloud)',
    description: 'applications.harvestDesc',
    url: 'https://external.sso.plume.com/home/oidc_client/0oagvr3jpqhV2GjKt357/aln177a159h7Zf52X0g8',
    icon: 'harvest.svg',
    sfProductName: 'Harvest',
    sfToolName: 'Harvest',
    environment: ['production', 'staging'] as string[],
    cloud: 'Gamma',
    featuresOptions: HarvestOptions as readonly RoleOption[],
  },
  {
    id: '0oagvr6khoU8ALxYZ357',
    name: 'Engage (Kappa cloud)',
    description: 'applications.harvestDesc',
    url: 'https://external.sso.plume.com/home/oidc_client/0oagvr6khoU8ALxYZ357/aln177a159h7Zf52X0g8',
    icon: 'harvest.svg',
    sfProductName: 'Harvest',
    sfToolName: 'Harvest',
    environment: ['production', 'staging'] as string[],
    cloud: 'Kappa',
    featuresOptions: HarvestOptions as readonly RoleOption[],
  },
  {
    id: '0oa16fzds984VYAhk0h8',
    name: 'Deal Registration',
    description: 'applications.vartopiaDesc',
    url: 'https://external-dev.sso.plume.com/home/dev-741674_vartopia_1/0oa16fzds984VYAhk0h8/aln16fzk7i6pKMZu80h8/',
    icon: 'vartopia.svg',
    sfProductName: 'Deal Registration',
    sfToolName: 'Deal Registration',
    environment: ['development'] as string[],
  },
  {
    id: '0oahik202d6PCkkGl357',
    name: 'Deal Registration',
    description: 'applications.vartopiaDesc',
    url: 'https://external.sso.plume.com/home/plume_vartopia_1/0oahik202d6PCkkGl357/alnhikcuy6PfJq0cF357',
    icon: 'vartopia.svg',
    sfProductName: 'Deal Registration',
    sfToolName: 'Deal Registration',
    environment: ['production', 'staging'] as string[],
  },
  {
    id: '0oa1q1zpx7cWBzMdR0h8',
    name: 'Partner Support',
    description: 'applications.zendeskDesc',
    url: `https://external-dev.sso.plume.com/home/bookmark/0oa1q1zpx7cWBzMdR0h8/1280`,
    icon: 'network-zendesk.svg',
    sfProductName: 'Partner Support',
    sfToolName: 'Partner Support',
    environment: ['development'] as string[],
  },
  {
    id: '0oath06ukmQBJSH3t357',
    name: 'Partner Support',
    description: 'applications.zendeskDesc',
    url: `https://external.sso.plume.com/home/bookmark/0oath06ukmQBJSH3t357/2557`,
    icon: 'network-zendesk.svg',
    sfProductName: 'Partner Support',
    sfToolName: 'Partner Support',
    environment: ['staging'] as string[],
  },
  {
    id: '0oath02b5sOUI9O2H357',
    name: 'Partner Support',
    description: 'applications.zendeskDesc',
    url: `https://external.sso.plume.com/home/bookmark/0oath02b5sOUI9O2H357/2557`,
    icon: 'network-zendesk.svg',
    sfProductName: 'Partner Support',
    sfToolName: 'Partner Support',
    environment: ['production'] as string[],
  },
  {
    id: '0oa168jriu5MkfBny0h8',
    name: 'Marketing Center',
    description: 'applications.seismicDesc',
    url: 'https://external-dev.sso.plume.com/home/seismic/0oa168jriu5MkfBny0h8/aln1juoxrs0zyGcZr1d8/',
    icon: 'network-seismic.svg',
    sfProductName: 'Marketing Center',
    sfToolName: 'Marketing Center',
    environment: ['development'] as string[],
  },
  {
    id: '0oajmzg32dSM2lIeB357',
    name: 'Marketing Center',
    description: 'applications.seismicDesc',
    url: 'https://external.sso.plume.com/home/plume_seismictest_1/0oajmzg32dSM2lIeB357/alnjmzqu38YB2Emkz357',
    icon: 'network-seismic.svg',
    sfProductName: 'Marketing Center',
    sfToolName: 'Marketing Center',
    environment: ['production', 'staging'] as string[],
  },
  {
    id: '0oa1xihdt4swfK3hU0h8',
    name: 'Customer Acquisition Portal',
    description: 'applications.capDescription',
    url: 'https://cap.plume-develop.com/auth/sign-in',
    icon: 'network-seismic.svg',
    sfProductName: 'Customer Acquisition Portal',
    sfToolName: 'Customer Acquisition Portal',
    environment: [] as string[],
  },
  {
    id: '0oayucs4qpc085rPZ357',
    name: 'Customer Acquisition Portal',
    description: 'applications.capDescription',
    url: 'https://cap.plume-preprod.com',
    icon: 'network-seismic.svg',
    sfProductName: 'Customer Acquisition Portal',
    sfToolName: 'Customer Acquisition Portal',
    environment: [] as string[],
  },
  {
    id: '0oaz22t2btFLjgXyv357',
    name: 'Customer Acquisition Portal',
    description: 'applications.capDescription',
    url: 'https://cap.plume.com',
    icon: 'network-seismic.svg',
    sfProductName: 'Customer Acquisition Portal',
    sfToolName: 'Customer Acquisition Portal',
    environment: [] as string[],
  },
] as const satisfies DeepReadonly<ApplicationConformation[]>

type IApplicationRaw = (typeof applicationsConstType)[number]

type IApplicationArray = DeepReadonly<
  Array<
    ApplicationConformation & {
      name: IApplicationRaw['name']
      sfProductName?: IApplicationRaw['sfProductName']
      sfToolName?: IApplicationRaw['sfToolName']
    }
  >
>
export type IApplication = IApplicationArray[number]
export type ApplicationEnvironment = IApplication['environment']

export const applications: IApplicationArray = applicationsConstType

export function getApplicationById(appId: IApplication['id'] | (string & {})): IApplication {
  return applications.find(a => a.id === appId)
}

export const isCorrectAppEnvironment = (environment: ApplicationEnvironment) => {
  const portalEnv = process.env.PORTAL_ENV
  if (portalEnv === 'local' || portalEnv === 'test') {
    return environment.includes('development')
  } else {
    return environment.includes(portalEnv)
  }
}

export type AutoToggleAppsRecord = Record<
  string,
  {
    toggledSelection?: boolean
    toggledRole?: string
    targetDispatches: {
      appId: string
      selected?: boolean
      role?: string
    }[]
  }[]
>

export type DependentAppsRecord = Record<
  string,
  {
    dependentRole?: string
    dependencyAppId: string
    allowedRoles?: string[]
  }[]
>
